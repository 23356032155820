<template>
  <!-- 绩效 -->
  <div class="myTaskShowBox">
    <div class="taskTesBox">
      <div class="tBox">
        <div class="Ptit"><strong>绩效列表 </strong></div>
        <div v-if="jxList.length === 0" class="taskTesBox">
          <el-empty description="暂无内容" />
        </div>
        <div v-else class="">
          <task-li-tes
            v-for="(cam, cax) in jxList"
            :key="cax"
            :iobj="cam"
          ></task-li-tes>
        </div>
        <div class="pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="parmas.total"
            @current-change="paginationPage"
          />
        </div>
      </div>
    </div>
    <div class="descriptions" v-show="Tadd">
      <div class="tBox">
        <div class="Ptit"><strong>添加绩效 </strong></div>
        <div class="Ptes">
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="85px"
            class="demo-ruleForm"
            :size="formSize"
          >
            <el-form-item label="类型">
              <el-radio-group v-model="ruleForm.type">
                <el-radio :label="1" @change="typeChange">日报</el-radio>
                <el-radio :label="2" @change="typeChange">周报</el-radio>
                <el-radio :label="3" @change="typeChange">月服</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="阶段" prop="cycle">
              <!-- format="[Week] ww" -->
              <!-- date/week/month -->
              <el-date-picker
                v-model="ruleForm.cycle"
                :type="dateType.type"
                placeholder="选择时间"
                :format="dateType.format"
                :value-format="dateType.val"
                @change="zhouzhou"
              />
            </el-form-item>
            <el-form-item label="相关URL">
              <el-input v-model="ruleForm.xgurl" />
            </el-form-item>
            <el-form-item label="工作内容">
              <!-- <el-input
                v-model="ruleForm.JobContent"
                :rows="6"
                type="textarea"
              /> -->
              <div v-show="edShow" id="editor-box"></div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm(ruleFormRef)"
                >确定</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, onMounted } from 'vue'
import { postAddPerformanc, postPerformancList } from '@/api/task'
import { sign, getItem, toUsId } from '@/utils/storage'
import TaskLiTes from '@/components/Public/TaskLiTes.vue'
import { ElMessage } from 'element-plus'
// import Editor from '../../article-create/components/Editor.vue'
import EP from 'wangeditor'
const edShow = ref(false) // 是否显示编辑器

// const Tobj = ref({}) // 任务
const Tadd = ref(true) // 是否可添加
const props = defineProps({
  taskObj: {
    type: Object,
    default: () => ({})
  }
})

// Editor实例
let editor
// 处理离开页面切换语言导致 dom 无法被获取
let el
onMounted(() => {
  el = document.querySelector('#editor-box')
  initEditor()
  var mid = toUsId(getItem('token'))
  // console.log('雇主和当前 -----', props.gUsID, mid)
  if (props.taskObj.gUsID === mid) {
    parmas.value.s = 'all'
  }
  getData()
  edShow.value = true
})

const initEditor = () => {
  editor = new EP(el)
  editor.config.zIndex = 1
  // 菜单栏提示
  editor.config.showMenuTooltips = true
  editor.config.menuTooltipPosition = 'down'

  // 国际化相关处理
  // editor.config.lang = store.getters.language === 'zh' ? 'zh-CN' : 'en'
  // editor.i18next = i18next

  editor.create()
}

const zhouzhou = ss => {
  console.log('选择的周---', ss)
}

const formSize = ref('default')
const ruleFormRef = ref()
const dateType = ref({
  type: 'date',
  format: 'YYYY/MM/DD',
  val: 'YYYY-MM-DD'
})
const ruleForm = ref({
  tsID: props.taskObj.tsid,
  tID: props.taskObj.taskID,
  cycle: '',
  tg: 'ok',
  xgurl: '',
  type: 1,
  etxt: ''
})
const rules = {
  cycle: [
    {
      type: 'date',
      required: true,
      message: '阶段不能为空',
      trigger: 'change'
    }
  ],
  JobContent: [{ required: true, message: '内容不能为空', trigger: 'blur' }]
}
const vv = ref(0)
const typeChange = v => {
  vv.value = v
  if (v === 1) {
    dateType.value = {
      type: 'date',
      format: 'YYYY/MM/DD',
      val: 'YYYY-MM-DD'
    }
  } else if (v === 2) {
    dateType.value = {
      type: 'week',
      // format: 'ww',
      format: 'YYYY/MM/DD',
      val: 'YYYY-MM-DD'
    }
  } else {
    dateType.value = {
      type: 'month',
      format: 'YYYY/MM',
      val: 'YYYY-MM'
    }
  }
}

const submitForm = async formEl => {
  ruleForm.value.etxt = editor.txt.html()
  if (!formEl) return
  if (vv.value === 2) {
    console.log(ruleForm.value.cycle)
  }
  if (ruleForm.value.xgurl === '') {
    // delete ruleForm.value.xgurl
    ruleForm.value.xgurl = '#'
  }

  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!', ruleForm.value)
      addPerformanc()
    } else {
      console.log('error submit!', fields)
    }
  })
}
const addPerformanc = async () => {
  var res = await postAddPerformanc(sign(ruleForm.value))
  if (res === 'ok') {
    ElMessage.success('添加成功！')
    // ruleForm.value.cycle = ''
    editor.txt.html('')
    ruleForm.value.etxt = ''
    ruleForm.value.cycle = ''
    ruleForm.value.xgurl = ''
    delete ruleForm.value.sign

    getData()
  }
}

const parmas = ref({
  page: 1,
  size: 10,
  total: 0,
  tsID: props.taskObj.tsid,
  tID: props.taskObj.taskID,
  s: 'i' // i查自己,all查所有人
})
const jxList = ref([])
const getData = async () => {
  parmas.value.sjs = Math.ceil(Math.random() * 10)
  var res = await postPerformancList(sign(parmas.value))
  res.list.forEach(elt => {
    if (elt.type === 1) {
      elt.rtes = '日报：'
    } else if (elt.type === 2) {
      elt.rtes = '周报：'
    } else {
      elt.rtes = '月报：'
    }
  })
  jxList.value = res.list
  parmas.value.total = res.total
  delete parmas.value.sign
}

const paginationPage = v => {
  parmas.value.page = v
  getData()
}

watch(
  () => props.taskObj,
  (newVal, oldVal) => {
    ruleForm.value.tsID = newVal.tsid
    parmas.value.tsID = newVal.tsid
    if (newVal.tsState === 5 || newVal.tsState === 6) {
      Tadd.value = false
    }
    // getData()
    // 逻辑代码
    console.log('newVal, oldVal', newVal, oldVal)
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped></style>
