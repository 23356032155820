<template>
  <!-- 阶段成果 -->
  <div class="myTaskShowBox">
    <div class="taskTesBox">
      <div class="tBox">
        <div class="Ptit"><strong>成果列表 </strong></div>
        <div v-if="jxList.length === 0" class="taskTesBox">
          <el-empty description="暂无内容" />
        </div>
        <div v-else class="">
          <task-li-tes
            v-for="(cam, cax) in jxList"
            :key="cax"
            :iobj="cam"
            rtes="阶段："
          ></task-li-tes>
        </div>
        <div class="pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="parmas.total"
            @current-change="paginationPage"
          />
        </div>
      </div>
    </div>
    <div class="descriptions" v-show="Tadd">
      <div class="tBox">
        <div class="Ptit"><strong>添加成果 </strong></div>
        <div class="Ptes">
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="85px"
            class="demo-ruleForm"
            :size="formSize"
          >
            <el-form-item label="阶段">
              <el-date-picker
                v-model="dateVal"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="截止时间"
                format="YYYY-MM-DD"
                value-format="YYYY/MM/DD"
                @change="dateValFun"
              />
            </el-form-item>
            <el-form-item label="上传文件">
              <el-upload
                class="upload-demo"
                method="post"
                action=""
                :before-upload="uploadsB"
                multiple
                :limit="1"
                :file-list="fileList"
                style="display:flex;text-align:left;"
              >
                <div class="el-upload__tip">
                  pdf/doc/docs/ppt/zip/jpg/png 格式，文件不超过 2M.
                </div>
                <el-button>上传文件</el-button>
              </el-upload>
              <div>
                <p
                  v-for="(ipm, ipx) in userIdDataB"
                  :key="ipx"
                  v-html="ipm"
                ></p>
              </div>
            </el-form-item>
            <el-form-item label="工作内容" prop="etxt">
              <div v-show="edShow" id="editor-box"></div>
            </el-form-item>
            <el-form-item label=" &nbsp;">
              <el-button type="primary" @click="submitForm(ruleFormRef)"
                >确定</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, onMounted } from 'vue'
import { postResultsList, postAddResults } from '@/api/task'
import { uploadData } from '@/api/user'
import { sign, getItem, toUsId } from '@/utils/storage'
import TaskLiTes from '@/components/Public/TaskLiTes.vue'
import { ElMessage } from 'element-plus'
import ER from 'wangeditor'

const Tadd = ref(true) // 是否可添加
const props = defineProps({
  taskObj: {
    type: Object,
    default: () => ({})
  }
})
const edShow = ref(false) // 显示编辑器

// 列表
const parmas = ref({
  page: 1,
  size: 10,
  total: 0,
  tsID: props.taskObj.tsid,
  tID: props.taskObj.taskID,
  s: 'i' // i查自己,all查所有人
})
const jxList = ref([])
const getData = async () => {
  parmas.value.sjs = Math.ceil(Math.random() * 10)

  var res = await postResultsList(sign(parmas.value))
  jxList.value = res.list
  parmas.value.total = res.total
  delete parmas.value.sign
}

const paginationPage = v => {
  parmas.value.page = v
  getData()
}

// Editor实例
let editor
// 处理离开页面切换语言导致 dom 无法被获取
let el
onMounted(() => {
  el = document.querySelector('#editor-box')
  initEditor()
  var mid = toUsId(getItem('token'))
  if (props.taskObj.addUserID === mid) {
    parmas.value.s = 'all'
  }
  // console.log('----results---onMounted---', props.taskObj)
  // getData()
  edShow.value = true
})

const fileList = ref([])
const userIdDataB = ref([])
const uploadsB = async (file, uploadFiles) => {
  var formData = new FormData()
  formData.append('file', file)
  formData.append('tg', 'ok')
  var revv = await uploadData(formData)
  if (ruleForm.value.file === '') {
    ruleForm.value.file = revv
  } else {
    ruleForm.value.file += ',' + revv
  }
  userIdDataB.value.push('<i class="el-icon-success FsL1" ></i> ' + revv)
  console.log('ruleForm.value--', ruleForm.value)
}

// 添加
const initEditor = () => {
  editor = new ER(el)
  editor.config.zIndex = 1
  // 菜单栏提示
  editor.config.showMenuTooltips = true
  editor.config.menuTooltipPosition = 'down'
  editor.create()
}
const dateVal = ref([])
const dateValFun = s => {
  console.log(s)
  ruleForm.value.cycle = s.join('-')
}
const formSize = ref('default')
const ruleFormRef = ref()
const ruleForm = ref({
  tsID: props.taskObj.tsid,
  tID: props.taskObj.taskID,
  cycle: '',
  tg: 'ok',
  type: 1,
  etxt: '',
  file: ''
})
const rules = {
  cycle: [{ required: true, message: '阶段不能为空', trigger: 'blur' }],
  etxt: [{ required: true, message: '内容不能为空', trigger: 'blur' }]
}
const submitForm = async formEl => {
  if (ruleForm.value.cycle === '') {
    ElMessage.error('请选择阶段')
    return
  }
  ruleForm.value.etxt = editor.txt.html()
  if (ruleForm.value.file === '') {
    delete ruleForm.value.file
  }
  if (!formEl) return
  console.log(ruleForm.value)

  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!', ruleForm.value)
      addPerformanc()
    } else {
      console.log('error submit!', fields)
    }
  })
}
const addPerformanc = async () => {
  console.log('ss---addPerformanc----99999')
  var res = await postAddResults(sign(ruleForm.value))
  if (res === 'ok') {
    ElMessage.success('添加成功！')
    // ruleForm.value.cycle = ''
    editor.txt.html('')
    ruleForm.value.etxt = ''
    // ruleForm.value.cycle = ''
    delete ruleForm.value.sign
    dateVal.value = ['', '']
    userIdDataB.value = []
    getData()
  }
}

watch(
  () => props.taskObj,
  (newVal, oldVal) => {
    ruleForm.value.tID = newVal.taskID
    ruleForm.value.tsID = newVal.tsid
    parmas.value.tID = newVal.taskID
    parmas.value.tsID = newVal.tsid
    if (newVal.tsState === 5 || newVal.tsState === 6) {
      Tadd.value = false
    }
    getData()
    // 逻辑代码
    // console.log('newVal, oldVal', newVal, oldVal)
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
.upload-demo > .el-upload {
  text-align: left !important;
}
</style>
