<template>
  <!-- 我的任务 - 详情 -->

  <div>
    <div class="Ptit">
      <strong class="tit">用户-任务详情</strong>
      <span class="rset">
        <template v-if="sq !== 5 && sq !== 6">
          <el-button
            size="mini"
            v-if="chexiaoBtnVis"
            plain
            @click="shenqingchexiao"
            >申请撤销</el-button
          >
          <el-button
            type="success"
            v-if="yanshouBtnVis"
            size="mini"
            @click="shenqingyanshou"
            >申请验收</el-button
          >
        </template>
      </span>
    </div>
    <div class="tastTiele">{{ taskObj.taskName }}</div>

    <div class="Mt12">
      <div class="nTabs yh y825">
        <span @click="nTabsFun('1')" :class="nTabs === '1' ? 'act' : ''">
          任务介绍
        </span>
        <span @click="nTabsFun('2')" :class="nTabs === '2' ? 'act' : ''">
          任务绩效
        </span>
        <span @click="nTabsFun('3')" :class="nTabs === '3' ? 'act' : ''">
          阶段成果
        </span>
        <span @click="nTabsFun('5')" :class="nTabs === '5' ? 'act' : ''">
          撤销审核
        </span>
        <span @click="nTabsFun('6')" :class="nTabs === '6' ? 'act' : ''">
          验收记录
        </span>
      </div>
      <div class="Mt12">
        <about v-if="nTabs === '1'" :taskObj="taskObj"></about>
        <performanc v-if="nTabs === '2'" :taskObj="taskObj"> </performanc>
        <results v-if="nTabs === '3'" :taskObj="taskObj"></results>
        <toapplyfor
          v-if="nTabs === '5'"
          :taskID="taskId"
          :tsID="tsID"
          :sq="sq"
          :gUsID="taskObj.addUserID"
          :taskObj="taskObj"
        ></toapplyfor>
        <acceptance
          v-if="nTabs === '6'"
          :taskObj="taskObj"
          :sq="sq"
          @chexiaoyanshou="chexiaoyanshouFun"
        ></acceptance>
      </div>
    </div>
  </div>
  <el-dialog
    v-model="dialoginVisible"
    :title="dialoginTitle"
    width="35%"
    style="min-width:320px;"
    :before-close="handleClose"
  >
    <div class="loginDialog">
      <div class="logMx">
        <div class="fxBox inpNum">
          <el-input
            class=""
            v-model="chexiaoTx"
            placeholder="请输入申请理由"
            :rows="8"
            type="textarea"
          >
          </el-input>
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="chexiao">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref } from 'vue'
import {
  getMyTaskShow,
  getToapplyfor,
  getUsTaskToapplyforTypeNum
} from '@/api/task'
import { sign } from '@/utils/storage'
import { useRouter } from 'vue-router'
import { ElMessageBox, ElMessage } from 'element-plus'
import About from './task/about'
import Performanc from './task/performanc' // 绩效
import Results from './task/results' // 阶段成果
import Toapplyfor from './task/toapplyfor' // 申请撤销
import Acceptance from './task/acceptance' // 申请验收
const router = useRouter()
const chexiaoBtnVis = ref(true) // 显示申请撤销按钮
const yanshouBtnVis = ref(true) // 显示申请验收按钮
const taskId = router.currentRoute.value.query.d
const tsID = ref(0) // 子任务ID
if (taskId === 'undefined' || taskId === null || taskId === '') {
  ElMessageBox.alert('此任务不存在~', '出错了', {
    confirmButtonText: 'OK',
    callback: action => {
      router.push('/usindex')
    }
  })
}

// 切换
const nTabs = ref('1')
const nTabsFun = vv => {
  nTabs.value = vv
}

// 详情
const taskObj = ref({})
const getData = async () => {
  var its = await getMyTaskShow(sign({ id: taskId }))
  taskObj.value = its
  tsID.value = its.tsid
  if (its.tsState === 5 || its.tsState === 6) {
    chexiaoBtnVis.value = false
    yanshouBtnVis.value = false
    sq.value = 5
  } else {
    shenqingNum()
  }
}
getData()

// 已经发送过的未回复的申请
const sq = ref(5) // 0可以申请，1已申请未回复，，5是已验收
const shenqingNum = async () => {
  // tID主任务，tsID子任务
  sq.value = await getUsTaskToapplyforTypeNum(
    sign({ tID: taskId, tsID: tsID.value })
  )
  console.log('已发申请=', sq.value)
}

// 申请撤销
const dialoginVisible = ref(false)
const dialoginTitle = ref('')
const shenqingType = ref(0)
const shenqingchexiao = () => {
  if (sq.value > 0) {
    ElMessage.error('已有申请还未回复，请耐心等待')
    return ''
  }
  shenqingType.value = 1
  dialoginTitle.value = '申请撤销'
  dialoginVisible.value = true
}
const chexiaoTx = ref('')
const chexiao = async () => {
  var parmas = {
    etxt: chexiaoTx.value,
    tID: taskId, // 主任务
    tg: 'ok',
    tsID: tsID.value, // 子任务
    type: shenqingType.value
  }
  var jg = await getToapplyfor(sign(parmas))
  if (jg === 'ok') {
    dialoginVisible.value = false
    chexiaoTx.value = ''
    sq.value = 1
    if (shenqingType.value === 2) {
      ElMessage.success('验收申请已经发送给雇主')
    } else if (shenqingType.value === 1) {
      ElMessage.success('撤销申请已经发送给雇主')
    } else {
      ElMessage.success('申请已经发送给雇主')
    }
  }
  console.log(jg)
}
// 申请验收
const shenqingyanshou = () => {
  if (sq.value > 0) {
    ElMessage.error('已有申请还未回复，请耐心等待')
    return ''
  }
  shenqingType.value = 2
  dialoginTitle.value = '申请验收'
  dialoginVisible.value = true
}
// 撤销和验收
const chexiaoyanshouFun = val => {
  // console.log('老页面---', val)
  sq.value = val
}
</script>

<style lang="scss" scoped>
.taskShowBox .taskTesBox {
  flex: 2;
}
</style>
