<template>
  <!-- 申请撤销 -->
  <div class="myTaskShowBox">
    <div class="taskTesBox">
      <div class="tBox">
        <!-- <div class="Ptit"><strong>申请撤销 </strong></div> -->
        <div v-if="jxList.length === 0" class="taskTesBox">
          <el-empty description="暂无内容" />
        </div>
        <div v-else class="">
          <to-apply-for-li
            v-for="(cam, cax) in jxList"
            :key="cax"
            :iobj="cam"
            rtes="阶段："
          ></to-apply-for-li>
        </div>
      </div>
      <div class="Mt24 TxC">
        <p v-if="ptgy === 1 && props.sq === 0">
          <el-button type="primary" @click="ptgy = 2" style="min-width:200px;"
            >向平台申诉</el-button
          >
        </p>
      </div>
      <div v-show="ptgy === 2" class="tBox">
        <br />
        <div class="Ptit"><strong>平台申诉 </strong></div>
        <div class="Ptes">
          <div v-show="editor1" id="editor-box"></div>
          <div class="Mt16">
            <el-button type="primary" @click="submitForm">提交平台</el-button>
          </div>
        </div>
      </div>
      <div v-show="ptgy === 3" class="tBox">
        <el-result
          icon="success"
          title="平台已接收到您的申诉"
          sub-title="请耐心等待平台运营人员审核"
        >
        </el-result>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, onMounted, defineEmits } from 'vue'
import { getMyToapplyforList, postAddResults, getToapplyfor } from '@/api/task'
// import { uploadData } from '@/api/user'
import { sign, getItem } from '@/utils/storage'
import ToApplyForLi from '@/components/Task/ToApplyForLi.vue'
import { ElMessage } from 'element-plus'
import E from 'wangeditor'

const props = defineProps({
  taskObj: {
    type: Object,
    default: () => ({})
  },
  taskID: {
    type: Number,
    default: 0
  },
  // 子任务ID
  tsID: {
    type: Number,
    default: 0
  },
  // 雇主
  gUsID: {
    type: Number,
    default: 0
  },
  // 申请次数
  sq: {
    type: Number,
    default: 0
  }
})

// 列表
const parmas = ref({
  tsID: props.taskObj.tsid,
  tID: props.taskObj.taskID,
  type: 1,
  tg: 'ok',
  s: 'i' // i查自己,all查所有人
})
const jxList = ref([])
const sqwhf = ref(0) // 已申请未回复数
const ptgy = ref(0) // 是否显示平台申诉按钮
const emits = defineEmits(['chexiaoyanshou'])
const getData = async () => {
  parmas.value.sjs = Math.ceil(Math.random() * 10)
  var res = await getMyToapplyforList(sign(parmas.value))
  const us = getItem('userinfo')
  var s = 0
  var ptss = false // 是否已向平台申诉
  res.forEach(itm => {
    itm.sqName = us.name
    itm.sqPhoto = us.photo
    itm.gz = false
    if (itm.state === 2) s++
    if (itm.ss === 1 && itm.type === 1) ptss = true
    if (itm.state === 0) sqwhf.value++
    // 如果验收或撤销申请被同意了
    if (itm.state !== 0 && (itm.type === 1 || itm.type === 2)) {
      // console.log('调用父页面，任务已验收')
      emits('chexiaoyanshou', 5)
    }
  })
  if (props.taskObj.tsState !== '6') {
    if (s > 1) ptgy.value = 1
    if (ptss) {
      ptgy.value = 3
    }
  }

  jxList.value = res
  delete parmas.value.sign
}
// getData()
watch(
  () => props.taskObj,
  (newVal, oldVal) => {
    console.log('--- yh - roapplyfor - watch - newVal, oldVal', newVal, oldVal)
    parmas.value.tsID = newVal.tsid
    parmas.value.tID = newVal.taskID
    getData()
    // 逻辑代码
  },
  {
    immediate: true
  }
)

// Editor实例
let editor
// 处理离开页面切换语言导致 dom 无法被获取
let el
onMounted(() => {
  el = document.querySelector('#editor-box')
  initEditor()
})

// 添加
const editor1 = ref(false)
const initEditor = () => {
  editor = new E(el)
  editor.config.zIndex = 1
  // 菜单栏提示
  editor.config.showMenuTooltips = true
  editor.config.menuTooltipPosition = 'down'
  editor.create()
  // editor.txt.html('')

  editor1.value = true
}

const submitForm = () => {
  var etxt = editor.txt.html()
  if (etxt === '') {
    ElMessage.error('请输入申诉内容')
    return false
  }
  addPerformanc(etxt)
}
const addPerformanc = async etxt => {
  console.log(postAddResults)
  var parmas = {
    etxt: etxt,
    tID: props.taskID, // 主任务
    tsID: props.tsID, // 子任务
    tg: 'ok',
    type: '1',
    ss: '1'
  }
  var jg = await getToapplyfor(sign(parmas))
  if (jg === 'ok') {
    ptgy.value = 3
    ElMessage.success('申请发送成功~')
  }
}
</script>

<style lang="scss" scoped>
.upload-demo > .el-upload {
  text-align: left !important;
}
</style>
