<template>
  <!-- 任务介绍 -->
  <div class="myTaskShowBox">
    <div class="taskTesBox">
      <el-collapse
        class="collapsWhg"
        v-model="activeNames"
        @change="handleChange"
      >
        <el-collapse-item name="1">
          <template #title>
            <strong class="dacu">任务内容</strong>
          </template>
          <div class="stext" v-html="taskObj.taskContent"></div>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template #title>
            <strong class="dacu">我的任务内容</strong>
          </template>
          <div class="stext">
            {{ taskObj.myContent }}
          </div>
          <!-- <div class="wcrwdjf">
            完成后可得：<span>{{ taskObj.tsIntegral }}</span> 积分
          </div> -->
        </el-collapse-item>
        <el-collapse-item name="3">
          <template #title>
            <strong class="dacu">验收标准</strong>
          </template>
          <div class="stext" v-html="taskObj.acceptanceCriteria"></div>
        </el-collapse-item>
      </el-collapse>
      <div class="tBox" style="display:none">
        <div class="Ptit"><strong>任务内容</strong></div>
        <div class="Ptes">
          {{ taskObj.taskContent }}
        </div>
      </div>
    </div>
    <div class="descriptions">
      <div class="tBox">
        <div class="Ptit"><strong>其他信息</strong></div>
        <el-descriptions column="1">
          <el-descriptions-item label="状态">
            <span v-if="taskObj.tsState === '0'" class="FsB1">进行中</span>
            <strong v-else-if="taskObj.tsState === '1'" class="FsZ1">
              申请撤销
            </strong>
            <span v-else-if="taskObj.tsState === '2'" class="">
              已申请验收
            </span>
            <span v-else-if="taskObj.tsState === '3'" class="">
              申请拒绝
            </span>
            <span v-else-if="taskObj.tsState === '4'" class="">
              平台申诉中
            </span>
            <span v-else-if="taskObj.tsState === '5'" class="">
              已完成
            </span>
            <span v-else-if="taskObj.tsState === '6'" class="FsB1">
              任务已撤销
            </span>
          </el-descriptions-item>
          <!-- <el-descriptions-item label="所属分类">
            <span class="FsB1">{{ taskObj.fieldLabel }}</span>
          </el-descriptions-item> -->
          <el-descriptions-item label="时间要求">
            <span class="FsB1">{{ taskObj.hours }}</span> 工时
          </el-descriptions-item>
          <el-descriptions-item label="计划时间">
            <span class="FsB1">{{ taskObj.planStartDate }}</span> 至
            <span class="FsB1">{{ taskObj.planEndDate }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="任务总积分">
            <span class="FsHs F14">{{ taskObj.integral }} 积分</span>
          </el-descriptions-item>
          <el-descriptions-item label="雇佣人数">
            <span class="FsB1">{{ taskObj.planPeopleNumber }} 人</span>
          </el-descriptions-item>
          <el-descriptions-item label="完成任务可得">
            <strong class="FsHs F14">{{ taskObj.tsIntegral }} 积分</strong>
          </el-descriptions-item>

          <el-descriptions-item label="承接人员类型">
            <span class="FsB1">{{
              taskObj.peopleType === 1
                ? '不限'
                : taskObj.peopleType === 2
                ? '企业'
                : '个人'
            }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="承接保证金">
            <span class="FsB1">{{ taskObj.zmargin }} 积分</span>
          </el-descriptions-item>
          <el-descriptions-item label="发布用户/企业">
            <span class="FsB1">{{ taskObj.name }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="发布时间">
            <span class="FsB1">{{ taskObj.insertTime }}</span>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps } from 'vue'
const props = defineProps({
  taskObj: {
    type: Object,
    default: () => ({})
  }
})
const activeNames = ref(['2', '3'])
watch(
  () => props.taskObj,
  (newVal, oldVal) => {
    // 逻辑代码    console.log('newVal, oldVal', newVal, oldVal)
  }
)
</script>

<style lang="scss" scoped></style>
